import React, { useEffect, useState } from 'react';
import { LockOutlined, EditOutlined, DownOutlined, SearchOutlined } from '@ant-design/icons';
import { Button, Card, Checkbox, Col, Dropdown, Flex, Form, Grid, Input, Layout, Row, Space, Table, MenuProps } from 'antd';
import { Content, Header } from 'antd/es/layout/layout';
import bida from './../assets/ic_bida.png';
import converge from './../assets/ic_converge.png';
import tindami from './../assets/ic_tindami.png';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { fetchAll } from '../api/recordsRequests';
import { useCookies } from 'react-cookie';
import { CSVLink } from 'react-csv'
import { Switch, capitalize } from '@mui/material';

const Records = (props) => {
    let loading = false;
    const navigate = useNavigate();
    const [records, setRecords] = useState([]);
    const [cookies, setCookie, removeCookie] = useCookies(['user']);
    const [sortAscending, setSortAscending] = useState(true)

    const fetchRecords = () => {
        loading = true;
        fetchAll((err, res) => {
            loading = false;
            if (res.affiliateRecords) {
                setRecords(res.affiliateRecords);
            }
        });
    }

    // const getRecords = () => {
    //     loading = true;
    //     fetchAll((err, res) => {
    //         loading = false;
    //         if (res.affiliateRecords) {
    //             transactionData(res.affiliateRecords.data);
    //             console.log(res.affiliateRecords.data);
    //             csvLink.current.link.click()
    //         }
    //     });
    // }

    const toDetails = (record) => {
        navigate('/records/details', { state: { record } });
    }

    const isCookieExpired = () => {
        if (cookies.user) {

            let expiration = cookies.user.options ? new Date(cookies.user.options.expires) : null;
            if (expiration < new Date()) {
                navigate('/');
            }
        }
        if (!cookies.user) {
            navigate('/');
        }
    };

    const logout = () => {
        removeCookie('user')
        navigate('/');
    };

    useEffect(() => {
        if (!loading) {
            fetchRecords();
        }

        const expirationCheckInterval = setInterval(() => {
            isCookieExpired();
        }, 1000); // Adjust the interval as needed

        // Clear the interval on component unmount
        return () => clearInterval(expirationCheckInterval);
    }, []);

    const statuses = {
        "openLead": "Open Lead",
        "interested": "Interested - Application Process",
        "notInterested": "Not Interes",
        "forFollowUp": "For Follow Up",
        "applicationApproved": "Application Approved",
        "applicationRejected": "Application Rejected",
        "forInstallation": "For Installation",
        "activated": "Activated",
    }
    const columns = [
        {
            title: 'Created',
            dataIndex: 'creation',
            key: 'creation',
            render: (text, record) => {
                return moment(record.creation).format("MMMM DD YYYY, h:mm:ss a")
            },
            sorter: (a, b) => a.creation > b.creation,

            // if (recordDetails && recordDetails.creation.includes('T')) {
            //     recordDetails.creation = moment(recordDetails.creation).format("MMMM DD YYYY, h:mm:ss a");
            // }

        },
        {
            title: 'Affiliate User',
            dataIndex: 'customer_code',
            key: 'customer_code',
            filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => {
                return <Input autoFocus placeholder="Type affiliate user here"
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => { confirm() }}
                    onBlur={() => {
                        confirm()
                    }}></Input>
            },
            filterIcon: () => {
                return <SearchOutlined />;
            },
            onFilter: (value, record) => {
                return record.customer_code.toLowerCase().includes(value.toLowerCase())
            }
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            render: (text, record) => <>{statuses[record.status]}</>,
            filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => {
                return <Input autoFocus placeholder="Type status here"
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => { confirm() }}
                    onBlur={() => {
                        confirm()
                    }}></Input>
            },
            filterIcon: () => {
                return <SearchOutlined />;
            },
            onFilter: (value, record) => {
                return record.status.toLowerCase().includes(value.toLowerCase().replace(/\s/g, ''))
            }
        },
        {
            title: 'Origin Ref #',
            dataIndex: 'ref_no',
            key: 'ref_no',
            filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => {
                return <Input autoFocus placeholder="Type ref # here"
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => { confirm() }}
                    onBlur={() => {
                        confirm()
                    }}></Input>
            },
            filterIcon: () => {
                return <SearchOutlined />;
            },
            onFilter: (value, record) => {
                return record.ref_no.toLowerCase().includes(value.toLowerCase())
            }
        },
        {
            title: 'Full Name',
            dataIndex: 'fullname',
            key: 'fullname',
            render: (text, record) => (
                <>{record.lastname}, {record.firstname}</>
            ),
            filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => {
                return <Input autoFocus placeholder="Type first name here"
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => { confirm() }}
                    onBlur={() => {
                        confirm()
                    }}></Input>
            },
            filterIcon: () => {
                return <SearchOutlined />;
            },
            onFilter: (value, record) => {
                return record.firstname.toLowerCase().includes(value.toLowerCase())
            }
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
            filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => {
                return <Input autoFocus placeholder="Type first name here"
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => { confirm() }}
                    onBlur={() => {
                        confirm()
                    }}></Input>
            },
            filterIcon: () => {
                return <SearchOutlined />;
            },
            onFilter: (value, record) => {
                return record.email.toLowerCase().includes(value.toLowerCase())
            }
        },
        {
            title: 'Mobile Number',
            dataIndex: 'mobile_no',
            key: 'mobile_no',
            filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => {
                return <Input autoFocus placeholder="Type first name here"
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => { confirm() }}
                    onBlur={() => {
                        confirm()
                    }}></Input>
            },
            filterIcon: () => {
                return <SearchOutlined />;
            },
            onFilter: (value, record) => {
                return record.mobile_no.toLowerCase().includes(value.toLowerCase())
            }
        },
        {
            title: 'Location',
            dataIndex: 'location',
            key: 'location',
            render: (text, record) => (
                <>{record.province}, {record.city}</>
            ),
            filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => {
                return <Input autoFocus placeholder="Type city here"
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => { confirm() }}
                    onBlur={() => {
                        confirm()
                    }}></Input>
            },
            filterIcon: () => {
                return <SearchOutlined />;
            },
            onFilter: (value, record) => {
                return record.city.toLowerCase().includes(value.toLowerCase())
            }
        },
        {
            title: '',
            key: 'action',
            render: (_, record) => (
                <Space size="middle">
                    <a onClick={() => toDetails(record)}><EditOutlined /> Edit</a>
                </Space>
            ),
        },
    ];

    const items = [
        {
            label: (
                <a target="_blank" rel="noopener noreferrer" onClick={logout}>
                    LOGOUT
                </a>
            ),
            key: '0',
        },
    ];

    const buttonStyle = {
        backgroundColor: '#1677FF',
        border: 'none',
        color: 'white',
        padding: '15px 32px',
        textAlign: 'center',
        textDecoration: 'none',
        display: 'inline-block',
        fontSize: '16px',
        margin: '4px 2px',
        cursor: 'pointer',
        borderRadius: '12px',
    };

    const exportToCSV = (records) => {
        const headers = Object.keys(records[0]).join(',') + '\n';
        const rows = records.map(record => Object.values(record).join(',')).join('\n');
        const csvString = headers + rows;
    
        // Create a Blob
        const blob = new Blob([csvString], { type: 'text/csv' });
    
        // Create a URL for the Blob
        const url = window.URL.createObjectURL(blob);
    
        // Create a link element
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'records.csv'); // Set the file name
    
        // Append the link to the body
        document.body.appendChild(link);
    
        // Trigger a click event on the link to start downloading
        link.click();
    
        // Clean up
        window.URL.revokeObjectURL(url);
        link.remove();
    }

    const ExportButton = () => {
        return (
            <div style={{alignSelf: 'flex-end'}}>
                <button onClick={() => exportToCSV(records)} style={buttonStyle}>Export to CSV</button>
            </div>
        );
    };

    return <Layout>

        <Header style={{ display: 'flex', alignItems: 'center', color: 'white' }}>
            {/* <LockOutlined /> */}
            <div style={{ marginRight: "15px", display: 'flex', }}><img alt="icon" src={tindami} height={30} /></div>
            <div style={{ marginRight: "15px", display: 'flex', }}><img alt="icon" src={bida} height={40} /></div>
            <div style={{ marginRight: "15px", display: 'flex', }}><img alt="icon" src={converge} height={40} /></div>
            <div style={{ width: '90%' }} />
            <Dropdown menu={{ items }} trigger={['click']}>
                <a onClick={(e) => e.preventDefault()} style={{ color: 'white' }}>
                    <Space>
                        {cookies.user ? cookies.user.value : null}
                        <DownOutlined />
                    </Space>
                </a>
            </Dropdown>
        </Header>
        <h1>Tindami Bida-Converge Referral Program</h1>
        <Content style={{ padding: '20px', height: '1000' }}>
            <Table sortDirections={["descend", "ascend"]} dataSource={records} columns={columns} />
            <ExportButton />
        </Content>
    </Layout>
};

export default Records;