const apiKey = process.env.REACT_APP_ApiKey;
const host = process.env.REACT_APP_Host;

exports.fetchAll = (resource, cb, resourceName) => {
    const options = {
        method: 'GET',
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json;charset=UTF-8",
            'x-api-key': apiKey,
        }
    };

    fetch(`${host}/${resource}?affiliate_company=Converge`, options)
    .then(response => {
        return response.json()
    })
    .then(res => {
        cb(null, res.data);
    }).catch(error => {
        cb(error, null);
    });
}

exports.post = (payload, resource, cb) => {

    const options = {
        method: 'POST',
        headers: {
            Accept: "application/json",
            "Content-Type": "multipart/form-data;application/json;charset=UTF-8",
            'x-api-key': apiKey,
        },
        body: JSON.stringify(payload)
    };

    fetch(`${host}/${resource}`, options)
    .then(response => {
        return response.json()
    })
    .then(res => {
        cb(null, res.data);
    }).catch(error => {
        cb(error, null);
    });
}

exports.update = (payload, resource, cb) => {
    const options = {
        method: 'PUT',
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json;charset=UTF-8",
            'x-api-key': apiKey,
        },
        body: JSON.stringify(payload),
    };

    fetch(`${host}/${resource}/`, options)
    .then(response => {
        return response.json()
    })
    .then(res => {
        cb(null, res.data);
    }).catch(error => {
        cb(error, null);
    });
}

exports.updateById = (id, payload, resource, cb) => {
    const options = {
        method: 'PUT',
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json;charset=UTF-8",
            'x-api-key': apiKey,
        },
        body: JSON.stringify(payload),
    };

    fetch(`${host}/${resource}/` + id, options)
    .then(response => {
        return response.json()
    })
    .then(res => {
        cb(null, res.data);
    }).catch(error => {
        cb(error, null);
    });
}

exports.getById = (id, resource, cb) => {
    const options = {
        method: 'GET',
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json;charset=UTF-8",
            'x-api-key': apiKey,
        }
    };

    fetch(`${host}/${resource}/` + id, options)
    .then(response => {
        return response.json()
    })
    .then(res => {
        cb(null, res.data);
    }).catch(error => {
        cb(error, null);
    });
}

exports.deleteById = (id, resource, cb) => {
    const options = {
        method: 'DELETE',
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json;charset=UTF-8",
            'x-api-key': apiKey,
        }
    };

    fetch(`${host}/${resource}/` + id, options)
    .then(response => {
        return response.json()
    })
    .then(res => {
        cb(null, res.data);
    }).catch(error => {
        cb(error, null);
    });
}