import React, { useState } from 'react';
import { Button, Dropdown, Form, Input, Layout, Select, Space } from 'antd';
import { useLocation, useNavigate } from 'react-router-dom';
import { update } from '../api/recordsRequests';
import moment from 'moment';
import { useCookies } from 'react-cookie';
import { Content, Header } from 'antd/es/layout/layout';
import { BackwardFilled, DownOutlined, CaretLeftOutlined } from '@ant-design/icons';
import bida from './../assets/ic_bida.png';
import converge from './../assets/ic_converge.png';
import tindami from './../assets/ic_tindami.png';

const formItemLayout = {
    labelCol: {
        xs: {
            span: 24,
        },
        sm: {
            span: 6,
        },
    },
    wrapperCol: {
        xs: {
            span: 24,
        },
        sm: {
            span: 14,
        },
    },
};

const { Option } = Select;

const recordFields = [
    {
        'label': 'ID',
        'key': 'id',
        'hidden': true,
    },
    {
        'label': 'Date created',
        'key': 'creation',
        'disabled': true,
    },
    {
        'label': 'Store Code',
        'key': 'customer_code',
        'disabled': true,
    },
    {
        'label': 'Tindami Reference Number',
        'key': 'ref_no',
        'disabled': true,
    },

    {
        'label': 'Status',
        'key': 'status',
    },
    {
        'label': 'First Name',
        'key': 'firstname',
    },
    {
        'label': 'Last Name',
        'key': 'lastname',
    },
    {
        'label': 'Email',
        'key': 'email',
    },
    {
        'label': 'Mobile Number',
        'key': 'mobile_no',
    },
    {
        'label': 'Province',
        'key': 'province',
    },
    {
        'label': 'City/Municipality',
        'key': 'city',
    },
    {
        'label': 'Barangay',
        'key': 'barangay',
    },
    {
        'label': 'Street Address',
        'key': 'address_line_1',
    },
    {
        'label': 'Landmark',
        'key': 'landmark',
    },
    {
        'label': 'Affiliated Company',
        'key': 'affiliate_company',
        'hidden': true,
    },
    {
        'label': 'Reference Number',
        'key': 'affiliate_ref_no',
    },
    {
        'label': 'Remarks',
        'key': 'affiliate_remarks',
    },
];

const RecordDetails = (props) => {
    const [disabled, setDisabled] = useState(false);
    const data = useLocation();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [cookies, setCookie, removeCookie] = useCookies(['user']);
    const [recordDetails, setRecordDetails] = useState(data.state.record);

    const isCookieExpired = () => {
        if (cookies.user) {

            let expiration = cookies.user.options ? new Date(cookies.user.options.expires) : null;
            if (expiration < new Date()) {
                navigate('/');
            }
        }
        if (!cookies.user) {
            navigate('/');
        }
    };

    // useEffect(() => {
    //     const expirationCheckInterval = setInterval(() => {
    //         isCookieExpired();
    //     }, 1000);

    //     return () => clearInterval(expirationCheckInterval);
    // }, []);

    const onFinish = (values) => {

        update([values], (err, res) => {
            if (res) {
                if (res.length) {
                    navigate(-1);
                    // setRecordDetails({ ...recordDetails, ...res[0] });
                }
            } else {
                console.log(err);
            }
        });
    };

    const logout = () => {
        removeCookie('user')
        navigate('/');
    };

    if (recordDetails && recordDetails.creation.includes('T')) {
        recordDetails.creation = moment(recordDetails.creation).format("MMMM DD YYYY, h:mm:ss a");
    }

    const items = [
        {
            label: (
                <a target="_blank" rel="noopener noreferrer" onClick={logout}>
                    LOGOUT
                </a>
            ),
            key: '0',
        },
    ];

    return <Layout>
        <Header style={{ display: 'flex', alignItems: 'center', color: 'white' }}>
            {/* <LockOutlined /> */}
            <div style={{ marginRight: "15px", display: 'flex', }}><img alt="icon" src={tindami} height={30} /></div>
            <div style={{ marginRight: "15px", display: 'flex', }}><img alt="icon" src={bida} height={40} /></div>
            <div style={{ marginRight: "15px", display: 'flex', }}><img alt="icon" src={converge} height={40} /></div>
            <div style={{ width: '90%' }} />
            <Dropdown menu={{ items }} trigger={['click']}>
                <a onClick={(e) => e.preventDefault()} style={{ color: 'white' }}>
                    <Space>
                        {cookies.user ? cookies.user.value : null}
                        <DownOutlined />
                    </Space>
                </a>
            </Dropdown>
        </Header>
        <Button
            //type="primary"
            //htmlType="submit"
            onClick={() => { navigate(-1) }}
            style={{ width: '5rem', margin: '20px 20px' }}
        >
            <CaretLeftOutlined />
        </Button>
        <Content style={{ padding: '20px 48px' }}>
            <Form
                {...formItemLayout}
                variant="filled"
                style={{
                    maxWidth: '80%',
                }}
                initialValues={recordDetails}
                onFinish={onFinish}
            >
                {recordFields.map(rec => {
                    return <Form.Item
                        label={rec.label}
                        key={rec.key}
                        hidden={rec.hidden || false}
                        disabled={rec.disabled || false}
                    >
                        <Form.Item
                            name={rec.key}
                            noStyle
                        // rules={[
                        //     {
                        //         required: true,
                        //         message: `${rec.label} is required`,
                        //     },
                        // ]}
                        >
                            {rec.key === 'status' &&
                                <Select placeholder="Select status" style={{ width: '100%', textAlign: 'left' }} value={recordDetails[rec.key]} >
                                    <Option value="openLead">Open Lead</Option>
                                    <Option value="interested">Interested - Application Process</Option>
                                    <Option value="notInterested">Not Interested</Option>
                                    <Option value="forFollowUp">For Follow Up</Option>
                                    <Option value="applicationApproved">Application Approved</Option>
                                    <Option value="applicationRejected">Application Rejected</Option>
                                    <Option value="forInstallation">For Installation</Option>
                                    <Option value="activated">Activated</Option>
                                </Select>
                            }

                            {rec.key !== 'status' &&
                                <Input name={rec.key} value={recordDetails[rec.key]} hidden disabled={rec.disabled || false} style={{ background: 'white' }} />
                            }
                        </Form.Item>
                    </Form.Item>
                })}

                <Form.Item
                    key='submit'
                    wrapperCol={{
                        offset: 6,
                        span: 16,
                    }}
                >
                    <Button type="primary" htmlType="submit">
                        Submit
                    </Button>
                </Form.Item>
            </Form>
        </Content>
    </Layout>;
};

export default RecordDetails;