import logo from './logo.svg';
import './App.css';
import Companies from './pages/affiliateCompanies';
import LoginForm from './pages/loginPage';
import Records from './pages/recordsPage';
import RecordDetails from './pages/recordDetailsPage';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import { CookiesProvider } from "react-cookie";

function App() {

  const router = createBrowserRouter([
    {
      path: '/',
      element: <LoginForm />
    },
    {
      path: "/records",
      element: <Records />,
    },
    {
      path: '/records/details',
      element: <RecordDetails />
    },
  ]);

  return (
    <CookiesProvider>
      <div className="App">
        <RouterProvider router={router} />
      </div>
    </CookiesProvider>
  );
}

export default App;
