const { updateById, fetchAll, post, update } = require("./request");

const resource = 'affiliate/records';

exports.fetchAll = (cb) => {
    fetchAll(resource, cb);
}

exports.update = (payload, cb) => {
    update(payload, resource, cb);
}

exports.post = (payload, cb) => {
    this.post(payload, resource, cb);
}