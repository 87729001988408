import React, { useState, useEffect } from 'react';
import { LockOutlined, UserOutlined } from '@ant-design/icons';
import { Button, Card, Checkbox, Form, Input, } from 'antd';
import combo_logo from './../assets/ic_combo.png';
import { useNavigate } from 'react-router-dom';
import { post } from '../api/authRequests';
import { useCookies } from 'react-cookie';

const LoginForm = () => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [cookies, setCookie] = useCookies(['user']);

    useEffect(() => {
        if (cookies.user) navigate('/records');
    }, []);

    const onFinish = (values) => {
        localStorage.setItem("username", values.username);
        localStorage.setItem("password", values.password);
        if (!loading) {

            setLoading(true)
            post({
                "email": values.username,
                "company": "Converge",
                "password": values.password,
            }, (err, res) => {
                setLoading(false);
                if (res) {
                    if (res.user) {
                        let expires = new Date();
                        expires.setTime(expires.getTime() + (1800 * 1000));
                        setCookie('user', JSON.stringify({ value: values.username, options: { expires, path: '/' } }), { expires: expires });
                        navigate("/records", { replace: true });
                    }
                }
            });
        }
    };

    return (
        <div
            style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100vh",
            }}
        >
            <Card style={{ width: 300 }}>
                <div style={{ display: "flex", justifyContent: "center" }}>
                    <Form.Item>
                        <img alt="icon" src={combo_logo} height={120} />
                    </Form.Item>
                </div>
                <Form
                    name="normal_login"
                    className="login-form"
                    initialValues={{ remember: false }}
                    onFinish={onFinish}
                // onSubmitCapture={handleSubmit}
                >
                    <Form.Item
                        name="username"
                        rules={[{ message: "Please input your Username!" }]}
                    >
                        <Input
                            prefix={<UserOutlined className="site-form-item-icon" />}
                            width={"100vh"}
                            placeholder="Username"
                        />
                    </Form.Item>
                    <Form.Item
                        name="password"
                        rules={[{ message: "Please input your Password!" }]}
                    >
                        <Input
                            prefix={<LockOutlined className="site-form-item-icon" />}
                            type="password"
                            placeholder="Password"
                        />
                    </Form.Item>
                    <Form.Item>
                        <Form.Item name="remember" valuePropName="checked" noStyle>
                            <Checkbox>Remember me</Checkbox>
                        </Form.Item>
                    </Form.Item>
                    <Form.Item>
                        <Button
                            type="primary"
                            htmlType="submit"
                            className="login-form-button"
                            block
                        >
                            Log in
                        </Button>
                    </Form.Item>
                </Form>
            </Card>
        </div>
    );
};

export default LoginForm;